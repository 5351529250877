import Client from "./Client";
import DashboardFilter from "./DashboardFilter";

export default class Dashboard {
    absenceUnit: string;
    dayCountBetweenTwoAbsenceEvents: number;
    client: Client;
    endDate: Date;
    filters: DashboardFilter[];
    id: string;
    name: string;
    startDate: Date;
    showStatsEvolution: boolean;
    showStatsEvolutionRolling: boolean;
    showStatsDispersion: boolean;
    showStatsSeverity: boolean;
    showStatsMicroAbsenteeism: boolean;
    showStatsDepartureRate: boolean;
    workingHoursByDay: number;

    years = () => {
        const years = [];
        if ('object' === typeof this.startDate && 'object' === typeof this.endDate) {
            for (let y = this.startDate.getFullYear(); y <= this.endDate.getFullYear(); y++) {
                years.push(y);
            }
        }

        return years;
    }
}
