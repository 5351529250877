import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import ResetPassword from "../pages/account/ResetPassword";
import Welcome from "../pages/account/Welcome";

// lazy load all the views
const PageNotFoundAlt = React.lazy(() => import("../pages/PageNotFoundAlt"));


const LandingPage = React.lazy(() => import('../pages/landing/index'));

const Home = React.lazy(() => import('../pages/home/Home'));

const CreateUser = React.lazy(() => import('../pages/users/CreateUser'));
const ListUser = React.lazy(() => import('../pages/users/ListUser'));

const CreateClient = React.lazy(() => import('../pages/clients/CreateClient'));
const ListClient = React.lazy(() => import('../pages/clients/ListClient'));

const CreateDashboard = React.lazy(() => import('../pages/dashboards/CreateDashboard'));
const ListDashboard = React.lazy(() => import('../pages/dashboards/ListDashboard'));
const ShowDashboard = React.lazy(() => import('../pages/dashboards/ShowDashboard'));
const ShowSocialMap = React.lazy(() => import('../pages/dashboards/ShowSocialMap'));
const ShowEvolution = React.lazy(() => import('../pages/dashboards/ShowEvolution'));
const ShowEvolutionRolling = React.lazy(() => import('../pages/dashboards/ShowEvolutionRolling'));
const ShowTypology = React.lazy(() => import('../pages/dashboards/ShowTypology'));
const ShowSeverity = React.lazy(() => import('../pages/dashboards/ShowSeverity'));
const ShowDispersion = React.lazy(() => import('../pages/dashboards/ShowDispersion'));
const ShowMicroAbsenteeism = React.lazy(() => import('../pages/dashboards/ShowMicroAbsenteeism'));
const ShowSeasonality = React.lazy(() => import('../pages/dashboards/ShowSeasonality'));
const ShowAccidentology = React.lazy(() => import('../pages/dashboards/ShowAccidentology'));
const ShowDepartureRate = React.lazy(() => import('../pages/dashboards/ShowDepartureRate'));
const Glossary = React.lazy(() => import('../pages/dashboards/Glossary'));
const Help = React.lazy(() => import('../pages/dashboards/Help'));
const AbsenceFileUpload = React.lazy(() => import('../pages/dashboards/AbsenceFileUpload'));
const EmployeeFileUpload = React.lazy(() => import('../pages/dashboards/EmployeeFileUpload'));

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const Logout = React.lazy(() => import('../pages/account/Logout'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            path: '/',
            element: <LoadComponent component={LandingPage} />,
        },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                { path: 'welcome/:token', element: <Welcome /> },
                { path: 'login', element: <Login /> },
                {
                    path: 'forget-password', children: [
                        {
                            path: '',
                            element: <ForgetPassword />,
                        },
                        {
                            path: ':token',
                            element: <ResetPassword />,
                        },
                    ]
                },
                { path: 'logout', element: <Logout /> },
                { path: '*', element: <PageNotFoundAlt /> }

            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={['ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_SUPERUSER', 'ROLE_USER']} component={VerticalLayout} />,
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: 'dashboards',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={ListDashboard} />,
                        },
                        {
                            path: ':dashboardId',
                            element: <LoadComponent component={ShowDashboard} />,
                        },
                        {
                            path: ':dashboardId/absence-file',
                            element: <LoadComponent component={AbsenceFileUpload} />,
                        },
                        {
                            path: 'create',
                            element: <LoadComponent component={CreateDashboard} />,
                        },
                        {
                            path: ':dashboardId/employee-file',
                            element: <LoadComponent component={EmployeeFileUpload} />,
                        },
                        {
                            path: ':dashboardId/social-map',
                            element: <LoadComponent component={ShowSocialMap} />,
                        },
                        {
                            path: ':dashboardId/evolution',
                            element: <LoadComponent component={ShowEvolution} />,
                        },
                        {
                            path: ':dashboardId/evolution-rolling',
                            element: <LoadComponent component={ShowEvolutionRolling} />,
                        },
                        {
                            path: ':dashboardId/typology',
                            element: <LoadComponent component={ShowTypology} />,
                        },
                        {
                            path: ':dashboardId/dispersion',
                            element: <LoadComponent component={ShowDispersion} />,
                        },
                        {
                            path: ':dashboardId/severity',
                            element: <LoadComponent component={ShowSeverity} />,
                        },
                        {
                            path: ':dashboardId/micro-absenteeism',
                            element: <LoadComponent component={ShowMicroAbsenteeism} />,
                        },
                        {
                            path: ':dashboardId/seasonality',
                            element: <LoadComponent component={ShowSeasonality} />,
                        },
                        {
                            path: ':dashboardId/accidentology',
                            element: <LoadComponent component={ShowAccidentology} />,
                        },
                        {
                            path: ':dashboardId/departure-rate',
                            element: <LoadComponent component={ShowDepartureRate} />,
                        },
                        {
                            path: ':dashboardId/glossary',
                            element: <LoadComponent component={Glossary} />,
                        },
                        {
                            path: ':dashboardId/help',
                            element: <LoadComponent component={Help} />,
                        },
                    ]
                },
                {
                    path: 'clients',
                    children: [
                        {
                            path: 'create',
                            element: <LoadComponent component={CreateClient} />,
                        },
                        {
                            path: '',
                            element: <LoadComponent component={ListClient} />,
                        },
                    ]
                },
                {
                    path: 'users',
                    children: [
                        {
                            path: 'create',
                            element: <LoadComponent component={CreateUser} />,
                        },
                        {
                            path: '',
                            element: <LoadComponent component={ListUser} />,
                        },
                    ]
                },
                { path: '*', element: <PageNotFoundAlt /> }
            ],
        },
    ]);
};

export { AllRoutes };
