import Client from "./Client";

export default class User {
    id: ?string;
    department: string;
    directSuperior: ?User;
    email: string;
    firstname: string;
    lastname: string;
    client: Client;
    job: string;
    roles: string[];
    enable: Boolean;

    constructor(id: ?string) {
        this.id = id;
    }

    get fullName(): string {
        return [this.firstname, this.lastname].join(" ");
    }
}
