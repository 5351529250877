// @flow
import { FiltersActionTypes } from './constants';

export type FilterType = {
    [key: string]: any,
};

export type FiltersAction = { type: string, payload?: any };

export const updateFilters = (filters: any): FiltersAction => ({
    type: FiltersActionTypes.UPDATE_FILTER,
    payload: filters,
});
