import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';

type PrivateRouteProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
    roles?: string[],
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const api = new APICore();
    const loggedInUser = api.getLoggedInUser();

    /**
     * not logged in so redirect to login page with the return url
     */
    if (api.isUserAuthenticated() === false) {
        return <Navigate to={'/login'} state={{ from: location }} replace />;
    }

    // check if route is restricted by role
    if (!api.userIsGranted(roles)) {
        console.debug('User has not enough roles.', 'Required roles:', roles, 'User roles:', loggedInUser.roles);
        // role not authorised so redirect to home page
        return <Navigate to={{ pathname: '/' }} />;
    }

    return <Component />;
};

export default PrivateRoute;
