// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import TopTitle from './topTitle/reducers';
import Dashboards from './dashboards/reducers';
import Filters from './filters/reducers';

export default (combineReducers({
    Auth,
    Layout,
    TopTitle,
    Dashboards,
    Filters,
}): any);
