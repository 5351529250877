// @flow
const groupByFields = (array: Array<any>, f: any): Array<any> => {
    /*
    params description :
        f : function which returnf the array of fields 
        e.g. :  (item) => {
            return [itemField1, itemField2];
        }
        array : array of data to group e.g. : [{...}, {...}]       
    */
    var groups = {};
    array.forEach((o) => {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map((group) => {
        return groups[group];
    });
};

/**
 * Transforms a list of Filters to a flat array of key values
 */
const apiParametersFromFilters = (filters: {[slug: string]: {label: string, value: string}|{label: string, value: string}[]}): {[slug: string]: string} =>  {
    const localFilters = structuredClone(filters);
    let values = {}
    delete localFilters['year-from'];
    delete localFilters['year-to'];
    delete localFilters['year'];

    Object.keys(localFilters).forEach(slug => {
        const options = localFilters[slug];
        if (Array.isArray(options) && options.length >= 1) {
            values[slug] = options.map(option => encodeURIComponent(option.value));
        } else {
            values[slug] = encodeURIComponent(options.value);
        }
    });
    return values;
}

const yearsFromFilters = (filters: {[slug: string]: {label: string, value: string}|{label: string, value: string}[]}): number[] => {
    const years = [];

    const fromFilter = filters['year-from'];
    const toFilter = filters['year-to'];
    const yearFilter = filters['year'];
    if (fromFilter !== undefined && toFilter !== undefined) {
        const from = parseInt(fromFilter.value);
        const to = parseInt(toFilter.value);

        if (from > to) {
            throw new Error(`'From' (${from}) cannot be superior to 'to' (${to})`);
        }
        for (let y = 0; y <= to - from; y++) {
            years.push(from + y);
        }
    } else if (yearFilter !== undefined) {
        years.push(parseInt(yearFilter.value));
    }

    return years;
}

export { groupByFields, apiParametersFromFilters, yearsFromFilters };
