// @flow
import { TopTitleActionTypes } from './constants';

const INIT_STATE = {
    title: null,
};

type TopTitleAction = { type: string, payload?: string | null };

type State = {
    title: ?title,
};

const TopTitle = (state: State = INIT_STATE, action: TopTitleAction): any => {
    switch (action.type) {
        case TopTitleActionTypes.CHANGE_TITLE:
            return {
                ...state,
                title: action.payload,
            };
        default:
            return state;
    }
};

export default TopTitle;
