import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';

const loading = () => <div className=""></div>;

const Routes = () => {
    return (
        <BrowserRouter basename="/">
            <AllRoutes />
        </BrowserRouter>
    );
};

export default Routes;
