import Activity from "./Activity";
import User from "./User";

export default class Client {
    activity: Activity;
    id: string;
    name: string;
    openOnSaturday: boolean = false;
    openOnSunday: boolean = false;
    user: User;

    constructor(id: ?string) {
        this.id = id;
    }
}
