// @flow
import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: any): any {
    const baseUrl = '/authentication_token';
    return api.create(`${baseUrl}`, params);
}

function logout(): any {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: any): any {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword({ email }: { email: string }): any {
    const baseUrl = '/forgot_password';
    return api.create(`${baseUrl}/`, { email });
}

function forgotPasswordCheckToken({ token }: { token: string }): any {
    const baseUrl = '/forgot_password';
    return api.get(`${baseUrl}/${token}`);
}

function forgotPasswordConfirm({ token, password }: { token: string, password: string }): any {
    const baseUrl = '/forgot_password';
    return api.create(`${baseUrl}/${token}`, { password });
}

export { login, logout, signup, forgotPassword, forgotPasswordCheckToken, forgotPasswordConfirm };
