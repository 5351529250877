// @flow
import React, { useEffect } from 'react';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

//actions
import {forgotPasswordCheckToken, forgotPasswordChange} from '../../redux/actions';

// components
import { VerticalForm, FormInput } from '../../components/';

import AccountLayout from './AccountLayout';
import Loader from "../../components/Loader";

/* bottom link */
const BottomLink = () => {
    const { t } = useTranslation();

    return (
        <Row className="mt-3">
            <Col className="text-center">
                <p className="text-muted">
                    {t('Back to')}{' '}
                    <Link to={'/login'} className="text-muted ms-1">
                        <b>{t('Log In')}</b>
                    </Link>
                </p>
            </Col>
        </Row>
    );
};

const InvalidToken = () => {
    const { t } = useTranslation();

    return (
        <>
            <AccountLayout bottomLinks={<BottomLink />}>
                <div className="text-center m-auto">
                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">{t('Reset Password')}</h4>
                    <Alert variant={"danger"}>
                        {t(
                            "This link is invalid or has expired. You may request a new password."
                        )}
                    </Alert>
                    <Button href={'/forget-password'} variant="primary">
                        <b>{t('Ask for a new password')}</b>
                    </Button>
                </div>
            </AccountLayout>
        </>
    )
}

const ResetPassword = (): React$Element<any> => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { token } = useParams();

    useEffect(() => {
        if (!token) {
            return;
        }
        dispatch(forgotPasswordCheckToken(token));
    }, [dispatch, token]);

    const { loading, passwordChange, checkTokenSuccess, error } = useSelector((state) => ({
        loading: state.Auth.loading,
        user: state.Auth.user,
        error: state.Auth.error,
        checkTokenSuccess: state.Auth.checkTokenSuccess,
        passwordChange: state.Auth.passwordChange,
    }));

    /*
     * form validation schema
     */
    const schemaResolver = yupResolver(
        yup.object().shape({
            password: yup
                .string()
                .min(8, 'Password too short')
                //.matches(passwordRegex, passwordMessage)
                .required(t('Please enter Password')),
            password_repeat: yup
                .string()
                .required(t('Please repeat Password'))
                .test(
                    'equal',
                    t('Passwords do not match!'),
                    function(v) { // Don't use arrow functions
                        const ref = yup.ref('password');
                        return v === this.resolve(ref);
                    }
                )
        })
    );

    /*
     * handle form submission
     */
    const onSubmit = (formData) => {
        dispatch(forgotPasswordChange(token, formData['password']));
    };

    if (checkTokenSuccess === undefined) {
        return <Loader />;
    }

    if (checkTokenSuccess === false) {
        return <InvalidToken />
    }

    return (
        <>
            <AccountLayout bottomLinks={<BottomLink />}>
                <div className="text-center m-auto">
                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">{t('Reset Password')}</h4>
                    <p className="text-muted mb-4">
                        {t(
                            "Enter your new password"
                        )}
                    </p>
                    {passwordChange &&
                        <>
                            <Alert variant="success">
                                {t('Your password has been updated! You can now login again')}
                            </Alert>
                            <Button variant={"primary"} href={"/login"}>{t('Log In')}</Button>
                        </>
                    }
                </div>

                {error && (
                    <Alert variant="danger" className="my-2">
                        {error}
                    </Alert>
                )}

                {!passwordChange && (
                    <VerticalForm onSubmit={onSubmit} resolver={schemaResolver}>
                        <FormInput
                            label={t('Password')}
                            type="password"
                            name="password"
                            placeholder={t('Enter your new password')}
                            containerClass={'mb-3'}
                        />

                        <FormInput
                            label={t('Repeat Password')}
                            type="password"
                            name="password_repeat"
                            placeholder={t('Repeat your new password')}
                            containerClass={'mb-3'}
                        />

                        <div className="mb-3 mb-0 text-center">
                            <Button variant="primary" type="submit" disabled={loading}>
                                {t('Submit')}
                            </Button>
                        </div>
                    </VerticalForm>
                )}
            </AccountLayout>
        </>
    );
};

export default ResetPassword;
