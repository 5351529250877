// @flow
import { DashboardsActionTypes } from './constants';
import Dashboard from "../../models/Dashboard";

export type DashboardsAction = { type: string, payload?: Dashboard | null };

export const changeList = (dashboards: Dashboard[]): DashboardsAction => ({
    type: DashboardsActionTypes.CHANGE_LIST,
    payload: dashboards,
});

export const changeActiveDashboard = (dashboard: Dashboard): DashboardsAction => ({
    type: DashboardsActionTypes.CHANGE_ACTIVE_DASHBOARD,
    payload: dashboard,
});
