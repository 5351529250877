// @flow
import { DashboardsActionTypes } from './constants';
import {DashboardsAction} from "./actions";
import Dashboard from "../../models/Dashboard";

const INIT_STATE = {
    list: [],
    activeDashboard: null,
};

type State = {
    list: Dashboard[],
    activeDashboard: ?Dashboard,
};

const Dashboards = (state: State = INIT_STATE, action: DashboardsAction): any => {
    switch (action.type) {
        case DashboardsActionTypes.CHANGE_LIST:
            return {
                ...state,
                list: action.payload,
            };
        case DashboardsActionTypes.CHANGE_ACTIVE_DASHBOARD:
            return {
                ...state,
                activeDashboard: action.payload,
            };
        default:
            return state;
    }
};

export default Dashboards;
