// @flow
import { FiltersActionTypes } from './constants';
import {FiltersAction} from "./actions";

const INIT_STATE = {
    filters: {},
};

type State = {
    filters: any,
};

const Filters = (state: State = INIT_STATE, action: FiltersAction): any => {
    switch (action.type) {
        case FiltersActionTypes.UPDATE_FILTER:
            console.debug('Existing filters', state.filters);
            console.debug('New filters', action.payload);
            return {
                ...state,
                filters: action.payload
            };
        default:
            return state;
    }
};

export default Filters;
